<template>
  <v-main class="main-view">
    <div class="overlay" v-if="!mini"></div>
    <v-container fluid>
      <router-view :key="$route.path" />
    </v-container>
    <div class="bg"></div>
  </v-main>
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "DefaultView",
  computed: {
    mini: get("app/mini"),
  },
  // methods: {
  //   listenMini() {
  //     console.log("click");
  //     if (!mini) mini = true;
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.main-view {
  position: absolute;
  left: 80px;
  width: calc(100% - 90px);
}
.overlay,
.bg {
  position: fixed; /* Sit on top of the page content */
  // display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.bg {
  background-color: #fafafa;
  z-index: -1;
}
</style>
